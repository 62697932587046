import { FC } from 'react';

import { ButtonVariant } from '@admin/atoms/Button';
import { OpenModalButton } from '@admin/molecules/OpenModalButton';
import { BaseModalProvider, BaseModalType } from '@admin/organisms/BaseModal';
import { NewsCategory } from '@common/clients/api';
import { ContextData } from '@common/defaults';
import { useTranslation } from '@pxr/i18n';

import { CreateModal } from './articles/organisms/CreateModal';

export interface State {
    dataIsOutdated: boolean;
}

export interface Props {
    contextData: ContextData;
    state: State;
    categories: NewsCategory[];
}

export const CreateArticle: FC<Props> = ({ categories }) => {
    const __translate = useTranslation('common').t;

    return (
        <BaseModalProvider>
            <div className="deprecated-container">
                <h2>{__translate('title.claimbox')}</h2>
                <OpenModalButton variant={ButtonVariant.positive} contextKey={BaseModalType.Create}>
                    {__translate('title.new')}
                </OpenModalButton>
                <CreateModal categories={categories} onSuccess={() => {}} __translationKey={'articles'} />
            </div>
        </BaseModalProvider>
    );
};

export default CreateArticle;
