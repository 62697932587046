import { GetServerSideProps, GetServerSidePropsContext } from 'next';

import { CreateArticle, Props, State } from '@admin/templates';
import { getNewsCategories } from '@admin/templates/news-category/helpers';
import { populateContextData } from '@common/defaults';
import { getAdminNamespacesByRoute, getServerTranslations } from '@pxr/i18n';

export const getServerSideProps: GetServerSideProps<Props> = async (context: GetServerSidePropsContext) => {
    const contextData = await populateContextData(context);

    const state: State = {
        dataIsOutdated: false,
    };

    const props: Props = {
        contextData,
        state,
        categories: [],
        ...(await getServerTranslations(
            contextData.context.locale,
            await getAdminNamespacesByRoute(contextData.path),
        )),
    };

    props.categories = await getNewsCategories({ contextData, isClientSide: false });

    return {
        props,
    };
};

export const Home = CreateArticle;
export default Home;
